/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import {graphql, useStaticQuery} from "gatsby";
import {Layout as AntLayout, Menu} from 'antd';
// import Header from "./header";
import "./layout.less";

const {Header, Footer, Content} = AntLayout;

const Layout = ({children, isHideHeader}: { children: any; isHideHeader?: boolean }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      {(!isHideHeader && <Header>
        <a href="/"
           className="logo"
           aria-label="Strohgami Logo">🎓</a>
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['3']}>
          {/*<Menu.Item key="1">Home</Menu.Item>*/}
          <Menu.Item key="1">Anmeldung</Menu.Item>
          <Menu.Item key="2">Fragen und Anworten</Menu.Item>
          <Menu.Item key="3">Hintergrundinformationen</Menu.Item>
        </Menu>
      </Header>)}

      <Content>
        <main>{children}</main>
      </Content>
      <Footer>
        © {new Date().getFullYear()} Strohgami GmbH
        {` `}
        <a href="/">Impressum</a>
      </Footer>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
